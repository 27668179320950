import org.khronos.webgl.Float32Array
import kotlin.math.min
import kotlin.math.pow
import kotlin.math.roundToInt


typealias OnFlushCallBack = (left: Float32Array, right: Float32Array) -> Unit

/* This solves https://github.com/AnthumChris/fetch-stream-audio/issues/11
 *
 * Controls decoded audio playback by filling a buffer and
 * flushing.  Assumes each sample is 4 bytes (float).
 * Grows exponentally to faciliate less-needed immediacy of playback and
 * fewer AudioBuffer objects created.
 * maxFlushSize and maxGrows are most likely the vals to tweak
 */
class DecodedAudioPlaybackBuffer(
    var onFlush: OnFlushCallBack
) {
    // use a 128K buffer
    val maxFlushSize = 1024L * 128L

    // exponentially grow over these many flushes
    // too small causes skips. 25 skips at 72kbps download, 64-kbit file
    val maxGrows = 50L

    // samples for first flush. grow from here. 20ms @ 48,000 hz
    val firstFlushLength = 0.02 * 48000

    // exponential grow coefficient from firstFlushLength samples to maxFlushSize bytes
    // Floating point is 4 bytes per sample
    val growFactor = (maxFlushSize / 4.0 / firstFlushLength).pow(1.0 / (maxGrows - 1.0))

    fun flushLength(flushCount: Long): Int {
        val flushes = min(flushCount, maxGrows - 1)
        val multiplier = growFactor.pow(flushes.toDouble())
        val length = (firstFlushLength * multiplier).roundToInt()
        return length
    }

    // left/right channels of buffers we're filling
    private var _bufferL = Float32Array(maxFlushSize.toInt())

    private var _bufferR = Float32Array(maxFlushSize.toInt())

    private var _bufferPos: Int = 0     // last filled position in buffer
    private var _onFlush: Long = 0       // user-provided function
    private var _flushCount: Long = 0    // number of times we've already flushed


    fun reset() {
        _bufferPos = 0
        _flushCount = 0
    }


    fun add(left: Float32Array, right: Float32Array) {
        val srcLen = left.length
        var bufferLen: Int
        var srcStart = 0
        var bufferPos = _bufferPos

        while (srcStart < srcLen) {
            bufferLen = flushLength(_flushCount)
            val len = min(bufferLen - bufferPos, srcLen - srcStart)
            val end = srcStart + len
            _bufferL.set(left.asDynamic().slice(srcStart, end) as Float32Array, bufferPos)
            _bufferR.set(right.asDynamic().slice(srcStart, end) as Float32Array, bufferPos)
            srcStart += len
            bufferPos += len
            _bufferPos = bufferPos
            if (bufferPos == bufferLen) {
                flush(/*bufferPos*/)
                bufferPos = 0
            }
        }
    }


    fun flush() {
        val bufferPos = this._bufferPos
        onFlush(
            _bufferL.asDynamic().slice(0, bufferPos) as Float32Array,
            _bufferR.asDynamic().slice(0, bufferPos) as Float32Array
        )
        _flushCount++
        _bufferPos = 0
    }
}