import jslib.opusstreamdecoder.DecodedCallBack
import jslib.opusstreamdecoder.OpusStreamDecoder
import jslib.opusstreamdecoder.OpusStreamDecoderOptions
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Float32Array
import org.khronos.webgl.Uint8Array
import org.w3c.dom.DedicatedWorkerGlobalScope
import org.w3c.dom.MessageEvent


external val self: DedicatedWorkerGlobalScope


/***
 * Based on: https://github.com/AnthumChris/fetch-stream-audio/blob/master/src/js/worker-decoder-opus.js
 */
fun main() {


    var sessionId: Number? = null
    var flushTimeoutId: Number? = null
    lateinit var playbackBuffer: DecodedAudioPlaybackBuffer

    fun evalSessionId(newSessionId: Number) {
        // detect new session and reset decoder
        if (sessionId != null && sessionId == newSessionId) {
            return
        }

        sessionId = newSessionId
        playbackBuffer.reset()
    }


    println("Worker is running...")

    playbackBuffer = DecodedAudioPlaybackBuffer(
        onFlush = { left, right ->
            val decodedMsg = AudioWorkerMessage(
                channelData = arrayOf(left, right),
                length = left.length,
                numberOfChannels = 2,
                sampleRate = 48000,
            )
            self.postMessage(decodedMsg)
        }
    )

    val onDecode: DecodedCallBack = { decoded ->
        // Decoder recovers when it receives new files, and samplesDecoded is negative.
        // For cause, see https://github.com/AnthumChris/opus-stream-decoder/issues/7
        if (decoded.samplesDecoded.toInt() >= 0) {
            playbackBuffer.add(decoded.left, decoded.right)
//            scheduleLastFlush()
        }
    }
    val options: OpusStreamDecoderOptions = Unit as OpusStreamDecoderOptions
    options.onDecode = onDecode
    val decoder = OpusStreamDecoder(options)

    self.onmessage = { m: MessageEvent ->
        if (m.data.asDynamic().type == "decodeOpus") {
            evalSessionId(m.data.asDynamic().sessionId as Number)
            decoder.ready.then {
                var success = false
                while (!success) {
                    try {
                        decoder.decode(Uint8Array(m.data.asDynamic().decode as ArrayBuffer))
                        success = true
                    } catch (t: Throwable) {
                        console.log("failed to decode audio, trying again")
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalJsExport::class)
@JsExport
class AudioWorkerMessage(
    var channelData: Array<Float32Array>,
    var length: Int,
    var numberOfChannels: Int,
    var sampleRate: Int
)